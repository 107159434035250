<template>
  <div style="width: 1200px">
    <div style="color: #666666;margin:20px;font-size: 12px;line-height: 17px;">广州高考网 > {{ title }}</div>
    <el-row>
      <!--  左侧树  -->
      <el-col :span="7">
        <el-card>
          <el-tree
              highlight-current
              default-expand-all
              :data="typeOptions"
              :props="defaultProps"
              accordion
              @node-click="handleNodeClick"
          ></el-tree>
        </el-card>
      </el-col>

      <!-- 右侧列表 -->
      <el-col :span="17">
        <el-card style="margin-left: 10px">
          <!-- 搜索框 -->
          <div>
            <el-input style="width: 85%" v-model="fileName" placeholder="请输入标题名称"></el-input>
            <el-button
                style="float: right;margin-right: 13px;color: #0080FF;width: 80px"
                       @click="findFileDocument"
                       type="primary">
              <span style="color:#f0f0f0;">搜索</span>
            </el-button>
          </div>
          <el-table
              :data="tableData"
              :cell-style="{padding: '0px'}"
              stripe>

<!--            <el-table-column width="100px">-->
<!--              <template scope="scope">-->
<!--                <div>-->
<!--                  <el-image :src="require('../../../assets/file.png')"></el-image>-->
<!--                </div>-->
<!--              </template>-->
<!--            </el-table-column>-->

            <el-table-column
                align="center"
                label="标题"
                width="600px">
              <template scope="scope">
                <div @click="openDetails(scope.row.id)">
                  <div style="color:#3D3D3D;font-size: 15px;float: left">{{ scope.row.name }}</div>
<!--                  <div style="color:#666666;font-size: 12px;margin-top: 10px">下载次数:&nbsp;{{-->
<!--                      scope.row.downCount-->
<!--                    }}次-->
<!--                  </div>-->
                </div>
              </template>
            </el-table-column>

            <el-table-column
                label="下载次数"
                align="center"
                width="70px">
              <template scope="scope">
                                    <div style="font-size: 12px;">
                                      {{
                                        scope.row.downCount
                                      }}
                                    </div>
              </template>
            </el-table-column>

            <el-table-column
            label="操作"
            align="center"
            >
              <template scope="scope">
                <el-button
                    @click="download(scope.row)"
                    style="color: #0080FF;"
                    type="text">
                  下载
                </el-button>
              </template>

            </el-table-column>
          </el-table>

          <!-- 分页相关  -->
          <div style="margin-top: 10px">
            <el-pagination
                background
                class="center"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.currentPage"
                :page-size="page.pageSize"
                layout="total, prev, pager, next, jumper"
                :total="page.total"
            >
            </el-pagination>

          </div>
        </el-card>
      </el-col>
    </el-row>

        <news/>

  </div>
</template>

<script>

import {downloadFileCheck, fetchTypeTree, findList} from "../../../api/front/topic";
import {downBlobFile} from "../../../utils";
import news from "../../../component/news";

export default {
  props: {
    dataType: {type: String}
  },
  components: {
    news
  },
  data() {
    return {
      // 导航type区分
      dataType: '',
      // 标题
      title: '',
      // 左侧分类框
      typeOptions: [],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 右侧列表数据
      tableData: [],
      // 模糊搜索数据
      fileName: '',
      // 当前的id
      id: '',
      // 分页数据相关
      page: {
        currentPage: 1,
        pageSize: 18,
        total: 0,
      }
    }
  },
  watch: {
    // 利用watch方法检测路由变化：
    $route: function (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.dataType = this.$route.params.dataType;
        this.id = '';
        this.toId();
        this.load();
      }
    }
  },
  methods: {
    // 打开详情页
    openDetails(id) {
      const url = this.$router.resolve({path: '/front/details', query: {id: id}})
      window.open(url.href, '_blank');
    },
    // 下载逻辑
    download(row) {
      downloadFileCheck(row.id).then(res => {
        if (res.data.code === 1) {
          this.$message.error(res.data.msg);
          return;
        }
        downBlobFile(row.id, row.name, row.type)
        this.findFileDocument();
      });
    },
    handleNodeClick(data) {
      this.id = data.id;
      this.findFileDocument()
    },
    load() {
      this.typeOptions = [];
      fetchTypeTree(Object.assign({
        dateType: this.dataType,
        id: this.id
      })).then(res => {
        const data = res.data.data[0];
        this.title = data.label;
        this.typeOptions.push(data);
        this.id = data.id;
        this.findFileDocument();
      })
    },
    findFileDocument() {
      findList(Object.assign({
        current: this.page.currentPage,
        size: this.page.pageSize,
        id: this.id,
        fileName: this.fileName
      })).then(res => {
        this.tableData = res.data.data.records;
        this.page.total = res.data.data.total;
      });
    },
    // 将id重置
    toId() {
      const type = this.dataType;
      if (type === 'all') {
        this.id = this.$route.query.id;
      }
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.findFileDocument();
    },
    handleCurrentChange(currentPage) {
      this.page.currentPage = currentPage
      this.findFileDocument();
    },
  },
  mounted() {
    this.dataType = this.$route.params.dataType;
    this.toId();
    this.load();
  },
}
</script>
<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
}

.center {
  .border {
    font-size: 18px;
    padding: 15px 15px 9px 15px;
    border-bottom: 1px solid #79bcff;
    background: #e1f0ff;
    color: #0080ff;
  }

  .more {
    float: right;
    font-size: 14px;
    color: #999999;
  }

  .step {
    position: relative;
  }

  .step:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #5eacf9;
  }
}

::v-deep {
  .el-divider--horizontal {
    margin: 9px 0 24px 0;
    color: #d8d8d8;
  }

  .el-menu-item {
    padding: 0 5px;
  }

  .el-menu--horizontal > .el-menu-item {
    height: 33px;
    line-height: 33px;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    color: #0080ff;
    border-bottom: 2px solid #0080ff;
  }

  .el-card.is-always-shadow,
  .el-card.is-hover-shadow:focus,
  .el-card.is-hover-shadow:hover {
    box-shadow: none;
  }

  .center {
    .el-card__body {
      padding: 0 0 15px 0;
    }
  }

  .footerBox {
    .el-card__body {
      padding: 15px;
    }
  }
}

::-webkit-scrollbar {
  width: 0px;
}

</style>
